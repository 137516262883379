import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="12" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8 11.4896L12.9062 8.61458L8 15.8281L3.0625 8.61458L8 11.4896Z" />
    <path d="M3.09375 7.61458L7.98438 0.15625L12.9062 7.61458L7.98438 10.4896L3.09375 7.61458Z" />
  </Svg>
)

export default Icon
