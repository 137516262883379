import React, { cloneElement, ElementType, isValidElement } from 'react'
import getExternalLinkProps from '../../util/getExternalLinkProps'
import StyledButton from './StyledButton'
import { ButtonProps, Scale, scales, Variant, variants } from './types'
import styled from 'styled-components'

export const BorderStyle = styled.div`
  display: block;
  border: 1px solid ${({ theme }) => theme.colors.brideCrumbsActiveItem};
  position: absolute;
  top: 8px;
  left: 5px;
  z-index: -1;
  width: 100%;
  height: 37px;
  // &:hover {
  // display: none
  // }
`

const Button = <E extends ElementType = 'button'>(props: ButtonProps<E>): JSX.Element => {
  const {
    startIcon,
    endIcon,
    external,
    className,
    isLoading,
    disabled,
    children,
    scale,
    variant,
    as,
    to,
    Border,
    ...rest
  } = props
  const internalProps = external ? getExternalLinkProps() : {}
  const isDisabled = isLoading || disabled
  const classNames = className ? [className] : []

  if (isLoading) {
    classNames.push('pancake-button--loading')
  }

  if (isDisabled && !isLoading) {
    classNames.push('pancake-button--disabled')
  }

  return (
    <div
      {...rest}
      style={
        Border && {
          width: '100%',
          position: 'relative',
          textAlign: 'center',
          zIndex: 1,
          margin: '0 auto',
          height: 48,
          ...rest,
        }
      }
    >
      <StyledButton
        $isLoading={isLoading}
        className={classNames.join(' ')}
        disabled={isDisabled}
        {...internalProps}
        {...rest}
        variant={variant}
      >
        <>
          {isValidElement(startIcon) &&
            cloneElement(startIcon, {
              mr: '0.5rem',
            })}
          {children}
          {isValidElement(endIcon) &&
            cloneElement(endIcon, {
              ml: '0.5rem',
            })}
        </>
      </StyledButton>
      {Border && <BorderStyle {...rest} style={{}} />}
    </div>
  )
}

Button.defaultProps = {
  isLoading: false,
  external: false,
  variant: variants.PRIMARY,
  scale: scales.MD,
  disabled: false,
}

export default Button
