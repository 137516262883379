import { createGlobalStyle } from 'styled-components'
import { SafegramTheme } from 'uikit'

declare module 'styled-components' {
  // @ts-ignore
  // eslint-disable-next-line
  export interface DefaultTheme extends SafegramTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
     font-family: 'Space Grotesk'
  }
   iframe {
      display: none;
    }
  body {
    background-color: ${({ theme }) => theme.colors.background};
    font-family: 'Space Grotesk'   
    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
