import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M8 0L16 8L8 16L0 8L8 0ZM8 2.24L13.76 8L8 13.76L2.24 8L8 2.24ZM8 10.88C8.76382 10.8801 9.4964 10.5767 10.0366 10.0367C10.5767 9.49666 10.8802 8.76416 10.8803 8.00033C10.8804 7.23651 10.5771 6.50394 10.037 5.96377C9.49699 5.4236 8.76449 5.12009 8.00067 5.12C7.23684 5.12 6.5043 5.42343 5.9642 5.96353C5.42409 6.50364 5.12067 7.23618 5.12067 8C5.12067 8.76382 5.42409 9.49636 5.9642 10.0365C6.5043 10.5766 7.23684 10.88 8.00067 10.88H8Z"/>
  </Svg>
);

export default Icon;
