import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.99905 11.334C10.4404 11.334 12.5757 12.384 13.7371 13.9507L12.5091 14.5313C11.5637 13.4113 9.89705 12.6673 7.99905 12.6673C6.10105 12.6673 4.43439 13.4113 3.48905 14.5313L2.26172 13.95C3.42305 12.3833 5.55772 11.334 7.99905 11.334ZM7.99905 1.33398C8.88311 1.33398 9.73095 1.68517 10.3561 2.3103C10.9812 2.93542 11.3324 3.78326 11.3324 4.66732V6.66732C11.3324 7.55137 10.9812 8.39922 10.3561 9.02434C9.73095 9.64946 8.88311 10.0007 7.99905 10.0007C7.115 10.0007 6.26715 9.64946 5.64203 9.02434C5.01691 8.39922 4.66572 7.55137 4.66572 6.66732V4.66732C4.66572 3.78326 5.01691 2.93542 5.64203 2.3103C6.26715 1.68517 7.115 1.33398 7.99905 1.33398V1.33398Z"/>
    </Svg>
  );
};

export default Icon;
