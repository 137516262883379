import React from 'react'
import { Box, Flex } from '../Box'
import { MoonIcon } from '../Svg'
import IconComponent from '../Svg/IconComponent'
import { Text } from '../Text'
import { Toggle } from '../Toggle'

export interface Props {
  isDark: boolean
  toggleTheme: (isDark: boolean) => void
  settingView?: boolean
}

const ThemeSwitcher: React.FC<Props> = ({ isDark, toggleTheme, settingView }) =>
  // <Toggle
  //   checked={isDark}
  //   defaultColor="textDisabled"
  //   checkedColor="textDisabled"
  //   onChange={() => toggleTheme(!isDark)}
  //   scale="md"
  //   startIcon={(isActive = false) => <IconComponent iconName="Sun" color={isActive ? 'textSubtle' : '#FFFFFF'} />}
  //   endIcon={(isActive = false) => <IconComponent iconName="Moon" color={isActive ? 'textSubtle' : '#FFFFFF'} />}
  // />
  settingView ? (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      width="40px"
      px="2px"
      height="20px"
      background={isDark ? '#F28761' : '#F5F5F5'}
      style={{ cursor: 'pointer' }}
      onClick={() => toggleTheme(!isDark)}
    >
      <Box />
      <Box width="16px" height="16px" background={isDark ? '#FFFFFF' : '#969EAD'} style={{ opacity: '0.3' }} />
    </Flex>
  ) : (
    <Flex onClick={() => toggleTheme(!isDark)} alignItems="center" style={{ gap: '5px', cursor: 'pointer' }}>
      <MoonIcon width={15} height={15} color="muted" />
      <Text color="muted">Dark</Text>
    </Flex>
  )

export default React.memo(ThemeSwitcher, (prev, next) => prev.isDark === next.isDark)
