import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M9.7606 6.66797H18.332V30.0013H8.33203L9.7606 6.66797Z" />
        <path d="M21.668 6.66797H30.2394L31.668 30.0013H21.668V6.66797Z" />
    </Svg>
  );
};

export default Icon;
