import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg  width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M5.5013 7.5013H17.1163L17.533 5.83464H7.16797V4.16797H18.6013C18.728 4.16797 18.853 4.19685 18.9668 4.25242C19.0806 4.30798 19.1803 4.38876 19.2582 4.48862C19.3362 4.58849 19.3903 4.7048 19.4165 4.82873C19.4428 4.95265 19.4404 5.08093 19.4096 5.2038L17.3263 13.5371C17.2812 13.7173 17.1771 13.8773 17.0306 13.9916C16.8842 14.1059 16.7037 14.168 16.518 14.168H4.66797C4.44695 14.168 4.23499 14.0802 4.07871 13.9239C3.92243 13.7676 3.83464 13.5556 3.83464 13.3346V3.33464H2.16797V1.66797H4.66797C4.88898 1.66797 5.10094 1.75577 5.25722 1.91205C5.4135 2.06833 5.5013 2.28029 5.5013 2.5013V7.5013ZM5.5013 19.168C5.05927 19.168 4.63535 18.9924 4.32279 18.6798C4.01023 18.3673 3.83464 17.9433 3.83464 17.5013C3.83464 17.0593 4.01023 16.6354 4.32279 16.3228C4.63535 16.0102 5.05927 15.8346 5.5013 15.8346C5.94333 15.8346 6.36725 16.0102 6.67981 16.3228C6.99237 16.6354 7.16797 17.0593 7.16797 17.5013C7.16797 17.9433 6.99237 18.3673 6.67981 18.6798C6.36725 18.9924 5.94333 19.168 5.5013 19.168ZM15.5013 19.168C15.0593 19.168 14.6354 18.9924 14.3228 18.6798C14.0102 18.3673 13.8346 17.9433 13.8346 17.5013C13.8346 17.0593 14.0102 16.6354 14.3228 16.3228C14.6354 16.0102 15.0593 15.8346 15.5013 15.8346C15.9433 15.8346 16.3673 16.0102 16.6798 16.3228C16.9924 16.6354 17.168 17.0593 17.168 17.5013C17.168 17.9433 16.9924 18.3673 16.6798 18.6798C16.3673 18.9924 15.9433 19.168 15.5013 19.168Z"/>
  </Svg>
);

export default Icon;
