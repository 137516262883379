import React from 'react'
import useTheme from 'hooks/useTheme'
import styled from 'styled-components'
import ConnectWallet from 'components/ConnectWallet'
import { Flex, Box } from '../Box'
import { Link } from '../Link'
import { StyledFooter, StyledList, StyledListItem, StyledText } from './styles'
import { FooterProps } from './types'
import { IconButton } from '../Button'
import { useMatchBreakpoints } from '../..'
import StarIcon from './../Svg/Icons/Star'

const StyledGrad = styled.div`
  position: absolute;
  width: 200px;
  height: 200px;
  bottom: 300px;
  right: 50px;
  background: #e291f2;
  opacity: 0.5;
  filter: blur(150px);
  transform: rotate(-60deg);
`

const MenuItem: React.FC<FooterProps> = ({
  items,
  isDark,
  toggleTheme,
  currentLang,
  langs,
  setLang,
  cakePriceUsd,
  buyCakeLabel,
  ...props
}) => {
  const { isMobile } = useMatchBreakpoints()
  const { isDark: isItDark } = useTheme()
  return (
    <StyledFooter p={['56px 48px', null, '56px 40px 32px 40px']} {...props} justifyContent="center">
      <Flex flexDirection="column" width='100%' style={{ maxWidth: 1220 }}>
        <Flex
          position="relative"
          bottom="100px"
          background="transparent"
          flexDirection="column"
          width={['100%', null, '100%']}
        >
          <Flex
            order={[2, null, 1]}
            flexDirection={['column', null, 'row']}
            justifyContent="space-around"
            alignItems="flex-start"
            width="100%"
          >
            {!isMobile && (
              <Flex mt="20px" flexDirection="column" alignItems="center" style={{ gap: '30px' }}>
                <Box>
                  <img src={isDark ? '/images/Logo.svg' : '/images/DarkLogo.svg'} alt="logo" width="50px" />
                </Box>
                <ConnectWallet scale="sm" />
              </Flex>
            )}

            {items?.map((item) => (
              <StyledList key={item.label}>
                <StyledListItem>
                  <StarIcon mr="5px" color="textSubtleSec" />
                  {item.label}
                </StyledListItem>

                {item.items?.map(({ label, href, isHighlighted = false }) => (
                  <StyledListItem key={label}>
                    {href ? (
                      <Link href={href} target="_blank" rel="noreferrer noopener" color="textSubtle" bold={false}>
                        {label}
                      </Link>
                    ) : (
                      <StyledText>{label}</StyledText>
                    )}
                  </StyledListItem>
                ))}
              </StyledList>
            ))}
            {isMobile && (
              <Flex justifyContent="center" width="100%">
                <Flex flexDirection="column" alignItems="center" style={{ gap: '30px' }}>
                  <Box>
                    <img
                      src={isDark ? '/images/Logo.svg' : '/images/DarkLogo.svg'}
                      alt="logo"
                      width="80px"
                      style={{ marginTop: '20px' }}
                    />
                  </Box>
                  <ConnectWallet scale="sm" />
                  <Flex justifyContent="center" alignItems="center">
                    <Box paddingBottom={isMobile ? '70px' : '30px'} width="200px">
                      <Flex justifyContent="space-around">
                        <IconButton>
                          <img alt="alt" src="/images/So.png" />
                        </IconButton>
                        <IconButton>
                          <img alt="alt" src="/images/Twitter.png" />
                        </IconButton>
                        <IconButton>
                          <img alt="alt" src="/images/Telegram.png" />
                        </IconButton>
                      </Flex>
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>

        {!isMobile && (
          <Flex justifyContent="center" alignItems="center">
            <Box paddingBottom={isMobile ? '70px' : 0} width="200px">
              <Flex justifyContent="space-around">
                <IconButton>
                  <img alt="alt" src="/images/Twitter.png" />
                </IconButton>
                <IconButton>
                  <img alt="alt" src="/images/Twitter.png" />
                </IconButton>
                <IconButton>
                  <img alt="alt" src="/images/Telegram.png" />
                </IconButton>
              </Flex>
            </Box>
          </Flex>
        )}
      </Flex>

      <StyledGrad />
    </StyledFooter>
  )
}

export default MenuItem
