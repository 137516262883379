import * as React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.6667 8.33333H8.33333V11.6667H11.6667V8.33333ZM13.3333 8.33333V11.6667H15.8333V8.33333H13.3333ZM11.6667 15.8333V13.3333H8.33333V15.8333H11.6667ZM13.3333 15.8333H15.8333V13.3333H13.3333V15.8333ZM11.6667 4.16667H8.33333V6.66667H11.6667V4.16667ZM13.3333 4.16667V6.66667H15.8333V4.16667H13.3333ZM6.66667 8.33333H4.16667V11.6667H6.66667V8.33333ZM6.66667 15.8333V13.3333H4.16667V15.8333H6.66667ZM6.66667 4.16667H4.16667V6.66667H6.66667V4.16667ZM3.33333 2.5H16.6667C16.8877 2.5 17.0996 2.5878 17.2559 2.74408C17.4122 2.90036 17.5 3.11232 17.5 3.33333V16.6667C17.5 16.8877 17.4122 17.0996 17.2559 17.2559C17.0996 17.4122 16.8877 17.5 16.6667 17.5H3.33333C3.11232 17.5 2.90036 17.4122 2.74408 17.2559C2.5878 17.0996 2.5 16.8877 2.5 16.6667V3.33333C2.5 3.11232 2.5878 2.90036 2.74408 2.74408C2.90036 2.5878 3.11232 2.5 3.33333 2.5Z" />
    </Svg>
  )
}

export default Icon
