import styled from 'styled-components'
import { StyledMenuItemProps } from './types'

export const StyledMenuItemContainer = styled.div<StyledMenuItemProps>`
  position: relative;

  ${({ $variant, theme, $isActive }) =>
    $variant === 'subMenu' &&
    `
      background: transparent;
      background-image: ${
        theme.isDark ? 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%);\n' : 'linear-gradient(180deg, rgba(242, 135, 97, 0) 0%, rgba(242, 135, 97, 0.1) 100%)'
      };
      border-bottom : ${$isActive ? (theme.isDark ? '2px solid #FFFFFF' : '2px solid #F28761') : '0px'};
      width: 87px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    `};

  ${({ $isActive }) =>
    !$isActive &&
    `
      background: transparent ;

    `};
`

const StyledMenuItem = styled.a<StyledMenuItemProps>`
  position: relative;
  display: flex;
  align-items: center;

  color: ${({ theme, $isActive, $variant }) =>
    $isActive ? (theme.isDark ? '#FFFFFF' : '#F28761') : theme.colors.muted};
  font-size: 14px;
  font-weight: ${({ $isActive }) => ($isActive ? '600' : '500')};

  ${({ $statusColor, theme }) =>
    $statusColor &&
    `
    &:after {
      content: "";
      border-radius: 100%;
      background: ${theme.colors[$statusColor]};
      height: 8px;
      width: 8px;
      margin-left: 12px;
    }
  `}

  ${({ $variant }) =>
    $variant === 'default'
      ? `
    padding: 0 16px;
    height: 48px;
  `
      : `
    padding: 4px 4px 0px 4px;
    height: 42px;
  `}

  &:hover {
    background: ${({ theme }) => theme.colors.hover};
    ${({ $variant }) => $variant === 'default' && 'border-radius: 16px;'};
  }
`

export default StyledMenuItem
