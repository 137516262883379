import useTheme from 'hooks/useTheme'
import React from 'react'
import { Box, Flex } from '../Box'
import StyledToggle, { Input, Handle } from './StyledToggle'
import { ToggleProps, scales } from './types'

const Toggle: React.FC<ToggleProps> = ({
  checked,
  defaultColor = 'background',
  checkedColor = 'backgroundAlt',
  scale = scales.LG,
  onChange,
  startIcon,
  endIcon,
  settingView,
  ...props
}) => {
  const isChecked = !!checked
  const { isDark } = useTheme()

  return settingView ? (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      width="40px"
      px="2px"
      height="20px"
      background={checked ? '#F2876' : isDark ? '#30343E' : '#F5F5F5'}
      style={{ cursor: 'pointer' }}
      onClick={onChange}
    >
      {/* <Input checked={checked} scale={scale} {...props} type="checkbox" /> */}

      <Box />
      <Box width="16px" height="16px" background={checked ? '#FFFFFF' : '#969EAD'} style={{ opacity: '0.3' }} />
    </Flex>
  ) : (
    <StyledToggle $checked={isChecked} $checkedColor={checkedColor} $defaultColor={defaultColor} scale={scale}>
      <Input checked={checked} scale={scale} {...props} type="checkbox" style={{ maxWidth: 40,
        width: "100%"}}/>
      {startIcon && endIcon ? (
        <>
          <Handle scale={scale}>
            <Flex height="100%" alignItems="center" justifyContent="center">
              {checked ? endIcon(checked) : startIcon(!checked)}
            </Flex>
          </Handle>
          <Flex width="100%" height="100%" justifyContent="space-around" alignItems="center">
            {startIcon()}
            {endIcon()}
          </Flex>
        </>
      ) : (
        <Handle scale={scale} />
      )}
    </StyledToggle>
  )
}

export default Toggle
