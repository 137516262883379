import React from 'react'
import { Button, useWalletModal } from 'uikit'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'

const ConnectWallet = (props) => {
  const { t } = useTranslation()
  const { isDark } = useTheme()

  return (
    <Button
      style={{
        borderRadius: 0,
        width: '150px',
        height: '40px',
        background: '#F28761',
        position: 'relative',
        color: isDark ? 'black' : 'white',
      }}
      //   onClick={onPresentConnectModal}
      {...props}
    >
      <Button
        color="text"
        style={{
          borderRadius: 0,
          background: 'transparent',
          border: `1px solid ${isDark ? '#FFFFFF' : '#F28761'}`,
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 5,
          left: 5,
          zIndex: -1,
        }}
      />
      {t('Connect Wallet')}
    </Button>
  )
}

export default ConnectWallet
