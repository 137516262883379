import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 16 16" width="16px" height="16px" {...props}>
      <path d="M11.3346 3.3335V4.66683C12.4393 4.66683 13.3346 5.56216 13.3346 6.66683V14.0002C13.3346 14.3682 13.036 14.6668 12.668 14.6668H3.33464C2.96664 14.6668 2.66797 14.3682 2.66797 14.0002V6.66683C2.66797 5.56216 3.5633 4.66683 4.66797 4.66683V3.3335H11.3346ZM8.66797 7.3335H7.33464V8.66683H6.0013V10.0002H7.33397L7.33464 11.3335H8.66797L8.6673 10.0002H10.0013V8.66683H8.66797V7.3335ZM12.668 1.3335V2.66683H3.33464V1.3335H12.668Z" />
    </Svg>
  );
};

export default Icon;
