import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 16 16" {...props}>
      <path d="M4.66667 4.00065V2.00065C4.66667 1.82384 4.7369 1.65427 4.86193 1.52925C4.98695 1.40422 5.15652 1.33398 5.33333 1.33398H13.3333C13.5101 1.33398 13.6797 1.40422 13.8047 1.52925C13.9298 1.65427 14 1.82384 14 2.00065V11.334C14 11.5108 13.9298 11.6804 13.8047 11.8054C13.6797 11.9304 13.5101 12.0007 13.3333 12.0007H11.3333V14.0007C11.3333 14.3687 11.0333 14.6673 10.662 14.6673H2.67133C2.58342 14.6678 2.49626 14.651 2.41488 14.6177C2.3335 14.5845 2.25949 14.5355 2.19711 14.4735C2.13472 14.4116 2.0852 14.3379 2.05137 14.2568C2.01754 14.1756 2.00009 14.0886 2 14.0007L2.002 4.66732C2.002 4.29932 2.302 4.00065 2.67333 4.00065H4.66667ZM3.33533 5.33398L3.33333 13.334H10V5.33398H3.33533ZM6 4.00065H11.3333V10.6673H12.6667V2.66732H6V4.00065Z" fill="#F28761"/>
    </Svg>
  );
};

export default Icon;
