import React from 'react'
import { useWeb3React } from '@web3-react/core'
import {
  Button,
  CogIcon,
  Flex,
  IconButton,
  LogoutIcon,
  Text,
  useModal,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
} from 'uikit'
import UserIcon from 'uikit/components/Svg/Icons/User'
import { useTranslation } from 'contexts/Localization'
import WalletModal, { WalletView, LOW_BNB_BALANCE } from './WalletModal'
import ConnectWalletButton from '../../ConnectWalletButton'
import { Link } from 'react-router-dom'

const UserMenu = ({ isDark }) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  //
  // if (!account) {
  //   return <ConnectWalletButton scale="sm" onClick={onPresentTransactionModal}/>
  // }

  return (
    <UIKitUserMenu account={account}>
      <UserMenuItem as="div" style={{ justifyContent: 'flex-start' }}>
        {isDark ? (
          <img style={{ width: '32px', height: '32px' }} alt="alt" src="/images/Avatar.svg" />
        ) : (
          <img style={{ width: '32px', height: '32px' }} alt="alt" src="/images/Avatar-light.svg" />
        )}
        <Text small ml="8px">
          {t('Guest')}
        </Text>
      </UserMenuItem>
      <div style={{ padding: '0px 8px' }}>
        <UserMenuDivider />
      </div>

      <UserMenuItem as={Link} to="/account">
        <Flex
          alignItems="center"
          justifyContent="flex-start"
          width="100%"
          style={{ color: `${({ theme }) => theme.colors.text}` }}
        >
          <UserIcon mr="8px" style={{ color: `${({ theme }) => theme.colors.text}` }} />
          {t('Account')}
        </Flex>
      </UserMenuItem>
      <UserMenuItem as={Link} to="/setting">
        <Flex
          alignItems="center"
          justifyContent="flex-start"
          width="100%"
          style={{ color: `${({ theme }) => theme.colors.text}` }}
        >
          <CogIcon mr="8px" width="20px" style={{ color: `${({ theme }) => theme.colors.text}` }} />
          {t('Setting')}
        </Flex>
      </UserMenuItem>
      <UserMenuItem as="button">
        <Flex
          alignItems="center"
          justifyContent="flex-start"
          width="100%"
          style={{ color: `${({ theme }) => theme.colors.text}` }}
        >
          <LogoutIcon mr="8px" width="20px" style={{ fill: `${({ theme }) => theme.colors.text}` }} />
          {t('Sign Out')}
        </Flex>
      </UserMenuItem>
    </UIKitUserMenu>
  )
}

export default UserMenu
