import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="8" height="12" viewBox="0 0 8 12" {...props}>
      <path d="M4.97656 5.99999L0.851562 1.87499L2.0299 0.696655L7.33323 5.99999L2.0299 11.3033L0.851562 10.125L4.97656 5.99999Z" />
    </Svg>
  )
}

export default Icon
{
  /* <svg  fill="none" xmlns="http://www.w3.org/2000/svg">
</svg> */
}
