import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="21" height="20" viewBox="0 0 21 20" {...props}>
      <path d="M18 3.3335V5.00016H17.1667L13 11.2502V18.3335H8V11.2502L3.83333 5.00016H3V3.3335H18ZM5.83667 5.00016L9.66667 10.7452V16.6668H11.3333V10.7452L15.1633 5.00016H5.83667Z" />
  </Svg>
);

export default Icon;
