import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 32 32" {...props}>
      <g clip-path="url(#clip0_851_24011)">
        <path d="M20.3669 8.52809C16.9317 8.52809 14.3348 9.29026 12.5763 10.8146C10.8178 12.2978 9.9385 14.5431 9.9385 17.5506V20.2079V28.2416V43.102C9.9385 43.598 9.53647 44 9.04054 44H0.898813C0.402883 44 0.000854492 43.598 0.000854492 43.102V17.6124C0.000854492 12.0506 1.71847 7.72472 5.15371 4.63483C8.62984 1.54494 13.476 0 19.6921 0C22.1458 0 24.4565 0.267789 26.6239 0.803368C28.4951 1.23018 30.153 1.84122 31.5975 2.6365C31.988 2.85149 32.1358 3.33121 31.9582 3.74007L29.5053 9.38634C29.3147 9.82523 28.813 10.0359 28.3621 9.87585C25.5036 8.86119 23.5158 8.52809 20.3669 8.52809Z"/>
        <path d="M21.3139 16.8992C21.4332 16.3669 22.2016 16.3669 22.3209 16.8992L23.1613 20.6481C23.5916 22.5679 25.1029 24.0715 27.044 24.5109L30.767 25.3537C31.3009 25.4746 31.3009 26.2254 30.767 26.3463L27.044 27.1891C25.1029 27.6285 23.5916 29.1321 23.1613 31.0519L22.3209 34.8008C22.2016 35.3331 21.4332 35.3331 21.3139 34.8008L20.4736 31.0519C20.0433 29.1321 18.5319 27.6285 16.5908 27.1891L12.8679 26.3463C12.3339 26.2254 12.3339 25.4746 12.8679 25.3537L16.5908 24.5109C18.5319 24.0715 20.0433 22.5679 20.4736 20.6481L21.3139 16.8992Z"/>
      </g>
      <defs>
        <clipPath id="clip0_851_24011">
          <rect width="32" height="44"/>
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
