import React from "react";
// import Svg from "./Svg";
// import { SvgProps } from "./types";

const Icon = () => (
    // <Svg viewBox="0 0 24 24" {...props}>
    <svg width="137" height="168" viewBox="0 0 137 168" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path style={{mixBlendMode:"screen"}} opacity="0.5" d="M81.9511 87.5286C100.637 87.5286 115.785 72.0594 115.785 52.9772C115.785 33.895 100.637 18.4258 81.9511 18.4258C63.2651 18.4258 48.1172 33.895 48.1172 52.9772C48.1172 72.0594 63.2651 87.5286 81.9511 87.5286Z" fill="url(#paint0_radial_753_51088)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M113.393 22.4386C129.408 31.6523 138.43 51.4157 130.039 67.6318C124.65 75.3828 116.669 80.8519 107.602 83.0075C98.5359 85.1631 89.0102 83.8561 80.8224 79.3332C72.7885 74.4505 66.8282 66.6818 64.1081 57.5476C61.388 48.4133 62.1041 38.5712 66.116 29.9477C76.0406 14.8373 97.3784 13.4552 113.393 22.4386ZM105.048 36.858C108.939 38.9935 111.861 42.5952 113.192 46.8969C114.523 51.1987 114.158 55.861 112.175 59.8923C110.994 61.7922 109.454 63.4323 107.645 64.7164C105.835 66.0006 103.793 66.9029 101.638 67.3706C99.4822 67.8383 97.257 67.8619 95.0926 67.44C92.9281 67.0181 90.8679 66.1593 89.0327 64.9137C80.642 59.9383 76.8074 49.3426 81.6795 41.8795C84.3131 38.2863 88.1404 35.7982 92.4428 34.8821C96.7453 33.9661 101.227 34.685 105.048 36.9041V36.858Z" fill="url(#paint1_linear_753_51088)"/>
        <path style={{mixBlendMode:"multiply"}} opacity="0.4"   fillRule="evenodd"   clipRule="evenodd" d="M118.082 78.8634C112.379 81.4328 106.164 82.5961 99.9385 82.2593C93.7135 81.9225 87.6533 80.095 82.2477 76.9245C76.842 73.7541 72.2425 69.3295 68.8207 64.0083C65.3989 58.6871 63.2507 52.6186 62.5499 46.293C62.4665 52.9793 64.1353 59.5668 67.3836 65.373C70.6319 71.1791 75.3413 75.9926 81.0241 79.3148C86.7068 82.637 93.1561 84.347 99.7037 84.2676C106.251 84.1883 112.659 82.3224 118.263 78.8634H118.082Z" fill="url(#paint2_radial_753_51088)"/>
        <path   fillRule="evenodd" clipRule="evenodd" d="M117.547 15.2074C125.663 19.8194 131.726 27.4499 134.474 36.5111C137.222 45.5723 136.442 55.3665 132.298 63.8558C123.546 80.809 101.126 85.1855 83.4421 74.774C65.7583 64.3625 58.0442 41.8811 68.2395 26.3099C73.6996 18.6085 81.7536 13.2387 90.8544 11.232C99.9553 9.22522 109.461 10.723 117.547 15.4377V15.2074ZM108.75 30.3179C112.884 32.5255 116.02 36.2824 117.5 40.8003C118.981 45.3183 118.69 50.2461 116.689 54.5499C112.404 62.8884 101.081 64.8693 92.0585 59.5253C83.0361 54.1814 78.8407 43.0328 83.9834 35.2933C86.7936 31.4897 90.8792 28.8788 95.4576 27.9607C100.036 27.0426 104.785 27.8819 108.795 30.3179H108.75Z" fill="url(#paint3_linear_753_51088)"/>
        <path   fillRule="evenodd"   clipRule="evenodd" d="M68.5961 26.6739C74.1913 19.2328 82.2352 14.1116 91.241 12.257C100.247 10.4024 109.606 11.9398 117.587 16.5849C122.516 19.356 126.747 23.2573 129.949 27.9817C133.151 32.7061 135.235 38.1248 136.038 43.8114C135.456 37.8694 133.468 32.1609 130.246 27.1773C127.024 22.1938 122.664 18.0844 117.542 15.2029C109.431 10.5116 99.9074 9.05013 90.8043 11.0999C81.7012 13.1496 73.6624 18.5657 68.2352 26.3054C67.4245 27.533 66.7153 28.8273 66.115 30.1751C64.9896 32.6742 64.2297 35.3284 63.8594 38.0528C64.5402 34.3746 65.9526 30.878 68.0096 27.7796C68.0096 27.5031 68.4156 27.2267 68.5961 26.9043V26.6739Z" fill="#EFC150"/>
        <path   fillRule="evenodd"   clipRule="evenodd" d="M112.174 59.5226C109.132 61.468 105.614 62.4997 102.024 62.4997C98.4344 62.4997 94.9169 61.468 91.8742 59.5226C88.8176 57.8401 86.2543 55.3577 84.4469 52.3301C82.6394 49.3024 81.653 45.8383 81.5887 42.293C81.299 46.1198 82.1094 49.9499 83.9204 53.3132C85.7314 56.6766 88.4644 59.427 91.784 61.2271C94.5578 62.9452 97.6994 63.9479 100.938 64.1486C104.176 64.3492 107.413 63.7418 110.37 62.3788C113.065 61.0605 115.278 58.8978 116.686 56.2056C118.178 53.2564 118.79 49.9245 118.445 46.6234C118.499 49.3769 117.879 52.1005 116.64 54.5472C115.565 56.5625 114.014 58.2724 112.129 59.5226H112.174Z" fill="#EFC150"/>
        <path style={{mixBlendMode:"multiply"}} opacity="0.5"   fillRule="evenodd"   clipRule="evenodd" d="M88.2645 35.9346C90.4268 32.6911 93.6903 30.3821 97.4237 29.4545C101.157 28.5269 105.095 29.0466 108.474 30.9132C111.803 32.8756 114.25 36.0873 115.295 39.8646C116.34 43.6418 115.9 47.6864 114.068 51.1372C114.214 53.9719 113.639 56.7969 112.399 59.3374C114.284 58.0872 115.835 56.3773 116.91 54.362C118.149 51.9154 118.769 49.1917 118.715 46.4382C118.661 43.0854 117.735 39.8076 116.03 36.9415C114.326 34.0754 111.905 31.7244 109.016 30.13C105.006 27.694 100.257 26.8547 95.6785 27.7728C91.1001 28.6909 87.0145 31.3018 84.2043 35.1054C82.9292 37.018 82.1813 39.2457 82.0391 41.555C83.6414 39.1976 85.8183 37.308 88.3547 36.0728V35.7503L88.2645 35.9346Z" fill="url(#paint4_radial_753_51088)"/>
        <path style={{mixBlendMode:"multiply"}} opacity="0.5"   fillRule="evenodd"   clipRule="evenodd" d="M131.664 59.8933C123.093 76.5701 101.078 80.8545 83.7103 70.6273C77.4777 67.1738 72.3483 61.9637 68.9332 55.6175C65.5181 49.2713 63.9611 42.0563 64.4476 34.832C60.8838 49.3436 68.9588 66.1586 83.4397 74.7735C101.123 85.1849 123.544 80.8084 132.296 63.8552C136.241 56.1133 137.253 47.1578 135.138 38.7018C136.221 45.9632 135.004 53.3881 131.664 59.8933Z" fill="url(#paint5_radial_753_51088)"/>
        <path   style={{mixBlendMode:"screen"}} fillRule="evenodd"   clipRule="evenodd" d="M92.2844 12.9891C86.1796 13.7986 80.4154 16.3254 75.6357 20.2872C70.8559 24.2491 67.2488 29.49 65.2174 35.4244C63.4129 41.2291 67.6534 42.7954 71.533 43.4864L75.0517 44.0853C75.6097 44.1795 76.1807 44.1588 76.7307 44.0243C77.2808 43.8898 77.799 43.6442 78.2547 43.3021C78.711 42.9695 79.0947 42.544 79.382 42.0524C79.6692 41.5609 79.8538 41.0139 79.9238 40.4459C79.9414 40.2775 79.9414 40.1076 79.9238 39.9391C80.291 37.8916 81.0747 35.9455 82.2244 34.2267C83.5067 32.4015 85.1136 30.8387 86.9613 29.6198C90.2893 27.3863 94.1571 26.1354 98.1375 26.0054C102.118 25.8753 106.056 26.8711 109.517 28.8827C112.216 30.3912 114.549 32.4986 116.345 35.05C118.141 37.6013 119.353 40.5314 119.893 43.6246C120.122 44.6638 120.697 45.5899 121.519 46.245C122.342 46.9002 123.361 47.244 124.404 47.218C127.231 47.218 130.028 46.9876 132.795 46.5269C137.938 45.9741 131.983 30.7255 125.577 24.0916C121.298 19.6431 116.021 16.3278 110.204 14.4332C104.387 12.5386 98.2049 12.122 92.1942 13.2194L92.2844 12.9891Z" fill="url(#paint6_radial_753_51088)"/>
        <path   fillRule="evenodd"   clipRule="evenodd" d="M22.8075 99.0039C31.5592 104.025 37.4689 107.895 45.6792 113.423L42.5213 119.274L47.0325 121.9L32.7322 147.146L27.2735 146.086L23.8902 151.844C16.0858 148.067 8.50706 143.783 0.0711651 138.715C-0.0935706 136.711 0.0280776 134.694 0.432136 132.726L9.45448 117.431L18.4768 122.499L22.988 114.898C20.7775 113.515 21.003 113.562 18.8377 112.18C18.7604 110.193 18.8662 108.203 19.1535 106.237C20.4617 103.841 21.77 101.63 23.1233 99.05L22.8075 99.0039Z" fill="url(#paint7_linear_753_51088)"/>
        <path   fillRule="evenodd"   clipRule="evenodd" d="M25.3338 94.3477C34.0855 99.3691 40.0854 103.285 48.2957 108.813C47.2581 110.748 46.1755 112.683 45.138 114.664L49.6491 117.244L34.4915 144.102L29.9803 141.476L26.4616 147.188C18.6573 143.457 8.73277 137.975 0.296875 132.677C4.26671 126.089 8.10119 119.409 11.8455 112.729L20.8678 117.797L25.1533 110.241L18.7024 106.187C20.9129 102.225 23.2136 98.2635 25.1985 94.3477H25.3338Z" fill="url(#paint8_radial_753_51088)"/>
        <path   style={{mixBlendMode:"screen"}} fillRule="evenodd"   clipRule="evenodd" d="M48.2973 108.813C40.087 103.285 34.0871 99.5995 25.3355 94.3477C23.1701 98.2635 21.0498 102.225 18.8394 106.187L25.2903 110.241L21.0048 117.797L11.9824 112.729C9.27571 117.566 6.47876 122.403 3.63672 127.241C18.5307 128.56 33.3611 124.066 45.1396 114.664C46.1771 112.683 47.2597 110.748 48.2973 108.813Z" fill="url(#paint9_radial_753_51088)"/>
        <path style={{mixBlendMode:"multiply"}}  fillRule="evenodd"   clipRule="evenodd" d="M41.845 104.621C45.2735 108.076 41.6194 119.778 37.3338 127.655C33.0482 135.533 24.8378 144.102 19.2891 143.503C21.8604 144.885 24.3415 146.175 26.6873 147.281L30.0707 141.568L34.5819 144.194L49.7395 117.336L45.2283 114.756C46.2659 112.775 47.3485 110.84 48.3861 108.905C46.2658 107.431 44.1005 106.003 41.9351 104.621H41.845Z" fill="url(#paint10_radial_753_51088)"/>
        <path d="M77.3489 85.412C79.8058 86.8763 82.3877 88.1099 85.063 89.0975C88.5366 90.2492 91.4238 89.6964 92.8674 87.1165C94.311 84.5367 93.3637 81.7726 90.657 79.2849C88.4852 77.4048 86.1439 75.7389 83.6646 74.3094C81.2268 72.8082 78.6413 71.5729 75.9505 70.624C74.6433 69.9147 73.1267 69.72 71.6882 70.0766C70.2497 70.4332 68.9894 71.3165 68.1461 72.5589C66.7025 75.1387 67.695 77.9489 70.4017 80.4366C72.5487 82.3281 74.8761 83.9949 77.3489 85.412Z" fill="url(#paint11_linear_753_51088)"/>
        <path style={{mixBlendMode:"multiply"}} opacity="0.5"   fillRule="evenodd"   clipRule="evenodd" d="M85.1111 89.0947C82.4358 88.1072 79.8539 86.8736 77.397 85.4093C74.9242 83.9922 72.5968 82.3254 70.4498 80.4339C69.4975 79.5883 68.7028 78.5738 68.104 77.4394C67.9636 77.1371 67.8906 76.8066 67.8906 76.472C67.8906 76.1373 67.9636 75.8069 68.104 75.5045C68.2638 75.2067 68.49 74.9515 68.7643 74.7594C69.0386 74.5673 69.3532 74.4438 69.6828 74.3989C70.9251 74.3238 72.1694 74.5121 73.337 74.9517C76.0164 75.929 78.5993 77.163 81.0511 78.6372C83.5373 80.0553 85.8795 81.722 88.0434 83.6126C88.996 84.4462 89.7779 85.4638 90.3441 86.607C90.5061 86.9026 90.5911 87.2357 90.5911 87.5745C90.5911 87.9132 90.5061 88.2463 90.3441 88.5419C90.1945 88.8387 89.9769 89.0942 89.7099 89.2867C89.4428 89.4792 89.1343 89.603 88.8103 89.6476C87.5535 89.7173 86.2957 89.5293 85.1111 89.0947Z" fill="url(#paint12_radial_753_51088)"/>
        <path   style={{mixBlendMode:"screen"}} fillRule="evenodd"   clipRule="evenodd" d="M70.1291 73.2476C70.0339 73.0547 69.9844 72.8417 69.9844 72.6257C69.9844 72.4097 70.0339 72.1966 70.1291 72.0037C73.4222 67.8576 88.1737 77.1174 88.1737 82.277C83.5619 78.1894 78.053 75.2975 72.114 73.8465C71.2569 73.8465 70.2193 73.8465 70.0389 73.2476H70.1291Z" fill="url(#paint13_radial_753_51088)"/>
        <path   fillRule="evenodd"   clipRule="evenodd" d="M89.1229 88.6855C80.1006 103.612 78.3862 106.468 69.815 121.578C61.2437 136.689 52.9432 151.984 44.778 167.324L34.4925 161.243L24.207 155.301C33.2294 140.374 41.9359 125.402 50.5523 110.338C59.1686 95.2733 60.6122 92.1406 68.597 76.7537C69.0932 75.8323 70.627 75.6941 72.7924 76.2009C75.5393 76.9089 78.169 78.0265 80.5967 79.5178C83.0617 80.9069 85.3117 82.6612 87.2733 84.7236C88.8071 86.382 89.4838 87.8562 88.9425 88.7776L89.1229 88.6855Z" fill="url(#paint14_linear_753_51088)"/>
        <path style={{mixBlendMode: "multiply"}} opacity="0.4"   fillRule="evenodd"   clipRule="evenodd" d="M87.4508 84.6325C86.2885 83.3807 85.0037 82.2538 83.6164 81.2695C74.5941 96.8868 72.6993 100.342 63.8574 115.959C55.0155 131.576 46.1736 147.286 37.4219 162.995L44.7751 167.326C52.9403 151.985 61.2409 136.69 69.8121 121.579C78.3833 106.469 80.2782 103.613 89.1201 88.6866C89.6614 87.7652 89.1199 86.291 87.4508 84.6325Z" fill="url(#paint15_linear_753_51088)"/>
        <path style={{mixBlendMode:"multiply"}} opacity="0.4"   fillRule="evenodd"   clipRule="evenodd" d="M27.1855 156.957C36.2078 141.386 45.2302 125.907 54.2525 110.244C63.2749 94.5807 64.6282 91.8166 72.9739 76.0611C70.8536 75.6005 69.3199 75.7847 68.8237 76.66C60.6585 92.0469 59.2149 95.0875 50.779 110.244C42.3431 125.401 33.2755 140.281 24.4336 155.207L27.411 156.957H27.1855Z" fill="url(#paint16_linear_753_51088)"/>
        <path   fillRule="evenodd"   clipRule="evenodd" d="M36.3413 158.016C41.9803 161.379 45.7696 165.525 44.7772 167.229C43.7847 168.934 38.3262 167.736 32.6421 164.419C26.958 161.102 23.2137 156.91 24.2062 155.206C25.1987 153.501 30.6572 154.699 36.3413 158.016Z" fill="url(#paint17_linear_753_51088)"/>
        <path   style={{mixBlendMode:"screen"}} fillRule="evenodd"   clipRule="evenodd" d="M80.3299 79.1438C78.3901 78.0078 76.3323 77.0962 74.1947 76.4258C50.3305 118.855 38.6467 141.475 40 142.258C41.3534 143.041 55.9695 121.988 80.1494 79.1438H80.3299Z" fill="url(#paint18_radial_753_51088)"/>
        <path style={{mixBlendMode:"multiply"}} opacity="0.4"   fillRule="evenodd"   clipRule="evenodd" d="M19.1986 143.41C20.7775 145.944 19.4693 146.542 17.4844 146.358C13.695 145.944 1.87568 139.402 1.10878 135.808C0.986098 135.4 0.999206 134.962 1.14609 134.562C1.29298 134.162 1.56535 133.822 1.92076 133.597L0.432136 132.676C0.0280776 134.644 -0.0935706 136.661 0.0711651 138.665C8.50706 143.732 16.0858 147.878 23.8902 151.794L27.2735 146.036L26.5969 147.187C24.2511 146.082 21.77 144.792 19.1986 143.41Z" fill="url(#paint19_linear_753_51088)"/>
        <path style={{mixBlendMode:"multiply"}} opacity="0.4"   fillRule="evenodd"   clipRule="evenodd" d="M22.8071 112.779C21.1831 113.7 18.9726 111.443 18.5666 109.001C18.4986 110.044 18.4986 111.091 18.5666 112.134C20.732 113.516 20.5065 113.47 22.717 114.852L25.3334 110.245L21.5892 107.941C22.2685 108.491 22.8617 109.144 23.3484 109.876C23.5236 110.092 23.6509 110.344 23.7214 110.615C23.7919 110.885 23.8038 111.168 23.7566 111.444C23.7094 111.72 23.6041 111.983 23.448 112.213C23.2918 112.443 23.0886 112.636 22.8523 112.779H22.8071Z" fill="url(#paint20_linear_753_51088)"/>
        <path   style={{mixBlendMode:"screen"}} fillRule="evenodd"   clipRule="evenodd" d="M92.2844 12.9891C86.1796 13.7986 80.4154 16.3254 75.6357 20.2872C70.8559 24.2491 67.2488 29.49 65.2174 35.4244C63.4129 41.2291 67.6534 42.7954 71.533 43.4864L75.0517 44.0853C75.6097 44.1795 76.1807 44.1588 76.7307 44.0243C77.2808 43.8898 77.799 43.6442 78.2547 43.3021C78.711 42.9695 79.0947 42.544 79.382 42.0524C79.6692 41.5609 79.8538 41.0139 79.9238 40.4459C79.9414 40.2775 79.9414 40.1076 79.9238 39.9391C80.291 37.8916 81.0747 35.9455 82.2244 34.2267C83.5067 32.4015 85.1136 30.8387 86.9613 29.6198C90.2893 27.3863 94.1571 26.1354 98.1375 26.0054C102.118 25.8753 106.056 26.8711 109.517 28.8827C112.216 30.3912 114.549 32.4986 116.345 35.05C118.141 37.6013 119.353 40.5314 119.893 43.6246C120.122 44.6638 120.697 45.5899 121.519 46.245C122.342 46.9002 123.361 47.244 124.404 47.218C127.231 47.218 130.028 46.9876 132.795 46.5269C137.938 45.9741 131.983 30.7255 125.577 24.0916C121.298 19.6431 116.021 16.3278 110.204 14.4332C104.387 12.5386 98.2049 12.122 92.1942 13.2194L92.2844 12.9891Z" fill="url(#paint21_radial_753_51088)"/>
        <path   style={{mixBlendMode:"screen"}} fillRule="evenodd"   clipRule="evenodd" d="M48.2973 108.813C40.087 103.285 34.0871 99.5995 25.3355 94.3477C23.1701 98.2635 21.0498 102.225 18.8394 106.187L25.2903 110.241L21.0048 117.797L11.9824 112.729C9.27571 117.566 6.47876 122.403 3.63672 127.241C18.5307 128.56 33.3611 124.066 45.1396 114.664C46.1771 112.683 47.2597 110.748 48.2973 108.813Z" fill="url(#paint22_radial_753_51088)"/>
        <path   style={{mixBlendMode:"screen"}} fillRule="evenodd"   clipRule="evenodd" d="M116.684 27.9636C116.693 33.5073 115.091 38.9291 112.08 43.5423C109.07 48.1555 104.787 51.7527 99.7731 53.8784C94.7595 56.0041 89.2408 56.5626 83.9161 55.4834C78.5914 54.4041 73.7001 51.7355 69.8615 47.8155C66.0229 43.8955 63.4098 38.9004 62.353 33.4628C61.2961 28.0252 61.8431 22.3895 63.9246 17.2696C66.0062 12.1496 69.5286 7.77555 74.046 4.70131C78.5634 1.62708 83.8725 -0.00907261 89.3011 3.78405e-05C96.5598 0.0122197 103.518 2.96229 108.65 8.20384C113.783 13.4454 116.672 20.551 116.684 27.9636Z" fill="url(#paint23_radial_753_51088)"/>
        <defs>
            <radialGradient id="paint0_radial_753_51088" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(81.951 52.9772) scale(33.8338 34.5514)">
                <stop stopColor="#AD6700"/>
                <stop offset="0.02" stopColor="#A56200"/>
                <stop offset="0.2" stopColor="#734500"/>
                <stop offset="0.38" stopColor="#4A2C00"/>
                <stop offset="0.55" stopColor="#2A1900"/>
                <stop offset="0.71" stopColor="#130B00"/>
                <stop offset="0.87" stopColor="#050300"/>
                <stop offset="1"/>
            </radialGradient>
            <linearGradient id="paint1_linear_753_51088" x1="64.9882" y1="31.9287" x2="129.575" y2="68.4388" gradientUnits="userSpaceOnUse">
                <stop stopColor="#BA7A1E"/>
                <stop offset="1" stopColor="#3B2008"/>
            </linearGradient>
            <radialGradient id="paint2_radial_753_51088" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(94.9852 32.4263) scale(52.51 53.6238)">
                <stop offset="0.64" stopColor="white"/>
                <stop offset="0.79" stopColor="#C3C7E6"/>
                <stop offset="1" stopColor="#616DBE"/>
            </radialGradient>
            <linearGradient id="paint3_linear_753_51088" x1="78.4347" y1="13.5029" x2="127.224" y2="80.6709" gradientUnits="userSpaceOnUse">
                <stop stopColor="#BA873B"/>
                <stop offset="1" stopColor="#6E390A"/>
            </linearGradient>
            <radialGradient id="paint4_radial_753_51088" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(109.016 68.2286) scale(42.0893 42.9819)">
                <stop stopColor="white"/>
                <stop offset="0.4" stopColor="#C3C7E6"/>
                <stop offset="1" stopColor="#616DBE"/>
            </radialGradient>
            <radialGradient id="paint5_radial_753_51088" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(26334.9 1532.17) scale(922.132 578.353)">
                <stop offset="0.64" stopColor="white"/>
                <stop offset="0.79" stopColor="#C3C7E6"/>
                <stop offset="1" stopColor="#616DBE"/>
            </radialGradient>
            <radialGradient id="paint6_radial_753_51088" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(94.2693 1.19554) scale(62.7956 64.1274)">
                <stop stopColor="#B18654"/>
                <stop offset="1" stopColor="#000505"/>
            </radialGradient>
            <linearGradient id="paint7_linear_753_51088" x1="15694.7" y1="2661.24" x2="16340.6" y2="2992.83" gradientUnits="userSpaceOnUse">
                <stop stopColor="#BA7A1E"/>
                <stop offset="1" stopColor="#3B2008"/>
            </linearGradient>
            <radialGradient id="paint8_radial_753_51088" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16838.8 2604.8) scale(713.633 764.074)">
                <stop stopColor="#BA873B"/>
                <stop offset="1" stopColor="#6E390A"/>
            </radialGradient>
            <radialGradient id="paint9_radial_753_51088" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15284.3 1518.46) scale(658.744 488.503)">
                <stop stopColor="#B18654"/>
                <stop offset="1" stopColor="#000505"/>
            </radialGradient>
            <radialGradient id="paint10_radial_753_51088" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10258 2087.01) scale(537.755 753.366)">
                <stop offset="0.64" stopColor="white"/>
                <stop offset="0.79" stopColor="#C3C7E6"/>
                <stop offset="1" stopColor="#616DBE"/>
            </radialGradient>
            <linearGradient id="paint11_linear_753_51088" x1="68.1461" y1="72.5588" x2="93.1236" y2="86.6628" gradientUnits="userSpaceOnUse">
                <stop stopColor="#BA7A1E"/>
                <stop offset="1" stopColor="#4A2808"/>
            </linearGradient>
            <radialGradient id="paint12_radial_753_51088" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(90.6598 95.7747) scale(54.5402 55.6968)">
                <stop stopColor="#616DBE"/>
                <stop offset="0.54" stopColor="#C3C7E6"/>
                <stop offset="0.89" stopColor="white"/>
            </radialGradient>
            <radialGradient id="paint13_radial_753_51088" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6536.67 546.303) scale(131.509 81.7871)">
                <stop stopColor="#B18654"/>
                <stop offset="1" stopColor="#000505"/>
            </radialGradient>
            <linearGradient id="paint14_linear_753_51088" x1="47.3494" y1="114.3" x2="68.0875" y2="125.993" gradientUnits="userSpaceOnUse">
                <stop stopColor="#855226"/>
                <stop offset="0.31" stopColor="#BA7A1E"/>
                <stop offset="1" stopColor="#3B2008"/>
            </linearGradient>
            <linearGradient id="paint15_linear_753_51088" x1="18271.6" y1="4650.84" x2="18548.7" y2="4747.23" gradientUnits="userSpaceOnUse">
                <stop stopColor="white"/>
                <stop offset="0.4" stopColor="#C3C7E6"/>
                <stop offset="1" stopColor="#616DBE"/>
            </linearGradient>
            <linearGradient id="paint16_linear_753_51088" x1="44.0121" y1="112.962" x2="58.552" y2="121.175" gradientUnits="userSpaceOnUse">
                <stop stopColor="#616DBE"/>
                <stop offset="0.6" stopColor="#C3C7E6"/>
                <stop offset="1" stopColor="white"/>
            </linearGradient>
            <linearGradient id="paint17_linear_753_51088" x1="7219.42" y1="999.567" x2="7315.92" y2="999.567" gradientUnits="userSpaceOnUse">
                <stop stopColor="#BA7A1E"/>
                <stop offset="1" stopColor="#3B2008"/>
            </linearGradient>
            <radialGradient id="paint18_radial_753_51088" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14141.3 3083.27) scale(749.672 1220.91)">
                <stop stopColor="#B18654"/>
                <stop offset="1" stopColor="#000505"/>
            </radialGradient>
            <linearGradient id="paint19_linear_753_51088" x1="9247.09" y1="1210.96" x2="9371.06" y2="1312.98" gradientUnits="userSpaceOnUse">
                <stop stopColor="white"/>
                <stop offset="0.4" stopColor="#C3C7E6"/>
                <stop offset="1" stopColor="#616DBE"/>
            </linearGradient>
            <linearGradient id="paint20_linear_753_51088" x1="2358.5" y1="460.366" x2="2367.01" y2="465.173" gradientUnits="userSpaceOnUse">
                <stop stopColor="white"/>
                <stop offset="0.4" stopColor="#C3C7E6"/>
                <stop offset="1" stopColor="#616DBE"/>
            </linearGradient>
            <radialGradient id="paint21_radial_753_51088" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25286.8 1031.89) scale(424.623 210.011)">
                <stop stopColor="#B18654"/>
                <stop offset="1" stopColor="#000505"/>
            </radialGradient>
            <radialGradient id="paint22_radial_753_51088" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15388.8 1641.99) scale(254.119 188.446)">
                <stop stopColor="#B18654"/>
                <stop offset="1" stopColor="#000505"/>
            </radialGradient>
            <radialGradient id="paint23_radial_753_51088" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(89.3011 27.9636) scale(27.4279 28.0097)">
                <stop stopColor="#FF9900"/>
                <stop offset="0.07" stopColor="#E18700"/>
                <stop offset="0.21" stopColor="#A66400"/>
                <stop offset="0.36" stopColor="#744500"/>
                <stop offset="0.5" stopColor="#4A2C00"/>
                <stop offset="0.64" stopColor="#2A1900"/>
                <stop offset="0.77" stopColor="#130B00"/>
                <stop offset="0.89" stopColor="#050300"/>
                <stop offset="1"/>
            </radialGradient>
        </defs>
    </svg>



);

export default Icon;
