import * as React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'
import { LocationIcon } from '../index'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg  width="15px" height="18px"  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15.3033 14.4703L10 19.7736L4.69667 14.4703C3.64779 13.4214 2.93349 12.085 2.64411 10.6301C2.35473 9.17528 2.50326 7.66729 3.07092 6.29684C3.63858 4.9264 4.59987 3.75507 5.83324 2.93096C7.0666 2.10686 8.51665 1.66699 10 1.66699C11.4834 1.66699 12.9334 2.10686 14.1668 2.93096C15.4001 3.75507 16.3614 4.9264 16.9291 6.29684C17.4968 7.66729 17.6453 9.17528 17.3559 10.6301C17.0665 12.085 16.3522 13.4214 15.3033 14.4703ZM10 10.8336C10.442 10.8336 10.866 10.658 11.1785 10.3454C11.4911 10.0329 11.6667 9.60895 11.6667 9.16693C11.6667 8.7249 11.4911 8.30098 11.1785 7.98842C10.866 7.67586 10.442 7.50026 10 7.50026C9.55798 7.50026 9.13405 7.67586 8.82149 7.98842C8.50893 8.30098 8.33334 8.7249 8.33334 9.16693C8.33334 9.60895 8.50893 10.0329 8.82149 10.3454C9.13405 10.658 9.55798 10.8336 10 10.8336Z"/>
    </Svg>
  )
}

export default Icon
