import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg  width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M32 7C20.9543 7 12 15.9543 12 27V57.6667L14.3166 56.5083C19.6873 53.823 25.8695 53.2484 31.6431 54.898L38.1013 56.7432C40.2455 57.3559 42.4647 57.6667 44.6947 57.6667H52V27C52 15.9543 43.0457 7 32 7ZM37.3335 23C35.1244 23 33.3335 24.7909 33.3335 27C33.3335 29.2091 35.1244 31 37.3335 31C39.5426 31 41.3335 29.2091 41.3335 27C41.3335 24.7909 39.5426 23 37.3335 23ZM22.6665 27C22.6665 24.7909 24.4574 23 26.6665 23C28.8756 23 30.6665 24.7909 30.6665 27C30.6665 29.2091 28.8756 31 26.6665 31C24.4574 31 22.6665 29.2091 22.6665 27ZM32 31C29.7909 31 28 32.7909 28 35V37.6667C28 39.8758 29.7909 41.6667 32 41.6667C34.2091 41.6667 36 39.8758 36 37.6667V35C36 32.7909 34.2091 31 32 31Z" fill="url(#paint0_linear_771_47283)"/>
    <defs>
      <linearGradient id="paint0_linear_771_47283" x1="16" y1="0.999999" x2="61.0521" y2="56.0984" gradientUnits="userSpaceOnUse">
        <stop stopColor="#94A1BF"/>
        <stop offset="1" stopColor="#929FC1" stopOpacity="0"/>
      </linearGradient>
    </defs>
  </Svg>
);
export default Icon;
