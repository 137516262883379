const variants = {
  warning: {
    background: 'rgba(173, 180, 194, 0.05)',
    borderColor: 'rgba(173, 180, 194, 0.15)',
  },

  danger: {
    background: '#ED4B9E19',
    borderColor: 'failure',
  },
}

export default variants
