import React, { useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import styled from 'styled-components'
import { Button } from 'uikit/components/Button'
import useTheme from 'hooks/useTheme'
import { Box, Flex } from '../../../../components/Box'
import { ChevronDownIcon } from '../../../../components/Svg'
import { UserMenuProps, variants } from './types'
import MenuIcon from './MenuIcon'
import { UserMenuItem } from './styles'
import EtherIcon from '../../../../components/Svg/Icons/Ether'
import NotificationIcon from '../../../../components/Svg/Icons/Notification'
import DownloadIcon from '../../../../components/Svg/Icons/Download'
import { Text } from '../../../../components/Text'
import { useMatchBreakpoints } from '../../../../hooks'

export const StyledUserMenu = styled(Flex)`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: 32px;

  // padding-right: 8px;
  position: relative;

  &:hover {
    opacity: 0.65;
  }
`

export const Notify = styled(Text)`
  background: #f28761;
  padding: 4px;
  border-radius: 50%;
  /* display: flex; */
  line-height: inherit;
  width: 20px;
  height: 20px;
  text-align: center;
`

const Menu = styled.div<{ isOpen: boolean }>`
  background-color: ${({ theme }) => theme.colors.greyBackground};
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  // border-radius: 16px;
  padding-bottom: 4px;
  padding-top: 4px;
  pointer-events: auto;
  width: 280px;
  visibility: visible;
  z-index: 1001;
  width: 200px;
  transform: translate(-25px, 68px) !important;
  ${({ isOpen }) =>
    !isOpen &&
    `
    pointer-events: none;
    visibility: hidden;
  `}

  ${UserMenuItem}:first-child {
    // border-radius: 8px 8px 0 0;
  }

  ${UserMenuItem}:last-child {
    // border-radius: 0 0 8px 8px;
  }
`

const UserMenu: React.FC<UserMenuProps> = ({
  account,
  text,
  avatarSrc,
  variant = variants.DEFAULT,
  children,
  ...props
}) => {
  const { isMobile } = useMatchBreakpoints()

  const [isOpen, setIsOpen] = useState(false)
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null)
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null)
  const accountEllipsis = account ? `${account.substring(0, 2)}...${account.substring(account.length - 4)}` : null
  const { styles, attributes } = usePopper(targetRef, tooltipRef, {
    strategy: 'fixed',
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
  })

  const { isDark } = useTheme()

  useEffect(() => {
    const showDropdownMenu = () => {
      setIsOpen(true)
    }

    const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
      const target = evt.target as Node
      if (target && !tooltipRef?.contains(target)) {
        setIsOpen(false)
        evt.stopPropagation()
      }
    }

    targetRef?.addEventListener('mouseenter', showDropdownMenu)
    targetRef?.addEventListener('mouseleave', hideDropdownMenu)

    return () => {
      targetRef?.removeEventListener('mouseenter', showDropdownMenu)
      targetRef?.removeEventListener('mouseleave', hideDropdownMenu)
    }
  }, [targetRef, tooltipRef, setIsOpen])

  return (
    <Flex alignItems="center" height="100%">
      {!isMobile &&
      <>
        <Flex
        onTouchStart={() => {
          setIsOpen((s) => false)
        }}
        mr="32px"
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
      >
        <NotificationIcon color="textSubtleSec" mr="2px" />
        <Notify small color="#181C27" fontWeight="500">
          11
        </Notify>
      </Flex>
        <Flex
          mr="20px"
          alignItems="center"
          justifyContent="flex-start"
          width="100%"
          style={{ color: `${({ theme }) => theme.colors.textSubtleSec}` }}
        >
          <DownloadIcon mr="8px" color="textGrey" />
          <Text small color="textSubtleSec">
            0
          </Text>
        </Flex>
        <Flex
          mr="24px"
          alignItems="center"
          justifyContent="flex-start"
          width="100%"
          style={{ color: `${({ theme }) => theme.colors.textSubtleSec}` }}
        >
          <EtherIcon mr="8px" color="textGrey" />
          <Text small color="textSubtleSec">
            0
          </Text>
        </Flex>
      </>
      }

      <Flex alignItems="center" height="100%" ref={setTargetRef} {...props}>
        <Flex alignItems="center" style={{ position: 'relative' }}>
          <StyledUserMenu
            onTouchStart={() => {
              setIsOpen((s) => !s)
            }}
          >
            <Button style={{ background: 'none', padding: 0 }}>
              {isDark ? (
                <img style={{ width: '32px', height: '32px' }} alt="alt" src="/images/Avatar.svg" />
              ) : (
                <img style={{ width: '32px', height: '32px' }} alt="alt" src="/images/Avatar-light.svg" />
              )}
            </Button>
          </StyledUserMenu>
        </Flex>
        <Menu style={styles.popper} ref={setTooltipRef} {...attributes.popper} isOpen={isOpen}>
          <Box onClick={() => setIsOpen(false)}>{children}</Box>
        </Menu>
      </Flex>
    </Flex>
  )
}

export default UserMenu
