import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 16 16" width="16px" height="16px" {...props}>
      <path d="M7.33278 10.6225C6.07674 10.4522 4.93222 9.81131 4.13068 8.82938C3.32914 7.84745 2.9304 6.59777 3.01509 5.33307C3.09977 4.06836 3.66157 2.88301 4.58687 2.0167C5.51216 1.1504 6.73191 0.667807 7.99945 0.666506C9.26854 0.665283 10.4906 1.14669 11.4179 2.01313C12.3452 2.87956 12.9083 4.06619 12.9931 5.33245C13.0779 6.5987 12.678 7.84981 11.8745 8.83215C11.071 9.81448 9.92404 10.4545 8.66612 10.6225V11.9998H11.9994V13.3332H8.66612V15.9998H7.33278V13.3332H3.99945V11.9998H7.33278V10.6225ZM7.99945 9.33317C8.97191 9.33317 9.90454 8.94687 10.5922 8.25923C11.2798 7.5716 11.6661 6.63897 11.6661 5.66651C11.6661 4.69405 11.2798 3.76142 10.5922 3.07378C9.90454 2.38615 8.97191 1.99984 7.99945 1.99984C7.02699 1.99984 6.09436 2.38615 5.40672 3.07378C4.71909 3.76142 4.33278 4.69405 4.33278 5.66651C4.33278 6.63897 4.71909 7.5716 5.40672 8.25923C6.09436 8.94687 7.02699 9.33317 7.99945 9.33317Z"/>
    </Svg>
  );
};

export default Icon;
