import React from 'react'
import { CogIcon, Flex, IconButton, useModal } from 'uikit'

type Props = {
  color?: string
  mr?: string
}

const GlobalSettings: React.FC<Props> = ({ color, mr }) => {
  // const [onPresentSettingsModal] = us
  return (
    <Flex>
      <IconButton variant="text" scale="sm" mr={mr} id="open-settings-dialog-button">
        <CogIcon height={15} width={15} mr={10} color="muted" />
      </IconButton>
    </Flex>
  )
  // return null
}

export default GlobalSettings
