import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8.60156 1.97996V2.93996" stroke="#6D7381"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.98047 8.59998H6.02047" stroke="#6D7381"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.12109 3.12L5.02109 5.02" stroke="#6D7381"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.4004 22.0199V21.0599" stroke="#6D7381" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22.0215 15.3999H19.0215" stroke="#6D7381" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M20.8915 20.89L19.0215 19.02" stroke="#6D7381" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.98094 15.3999H2.94094" stroke="#6D7381" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.60156 22.02V18.02" stroke="#6D7381" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.12109 20.89L5.00109 19" stroke="#6D7381" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22.021 8.59998H21.061" stroke="#6D7381" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.4004 1.97998V6.01998" stroke="#6D7381" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M20.89 3.12L19 5" stroke="#6D7381" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M22.021 8.59998H21.061"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M20.89 3.12L19 5"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>

    <path fillRule="evenodd" clipRule="evenodd" d="M12.0176 7.02124L17.0175 12.0211L12.0176 17.021L7.01767 12.0211L12.0176 7.02124Z"  strokeWidth="1.49999" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
);

export default Icon;
