import styled from 'styled-components'
import Button from './Button'
import { BaseButtonProps, PolymorphicComponent } from './types'

const IconButton: PolymorphicComponent<BaseButtonProps, 'button'> = styled(Button)<BaseButtonProps>`
  padding: 0;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  box-shadow: none;
  background-color: transparent;
  border: 0!important;
`

export default IconButton
