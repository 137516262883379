import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg  width="14" height="15" viewBox="0 0 14 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12.332 10.334H13.6654V11.6673H0.332031V10.334H1.66536V5.66732C1.66536 4.25283 2.22727 2.89628 3.22746 1.89608C4.22766 0.895888 5.58421 0.333984 6.9987 0.333984C8.41319 0.333984 9.76974 0.895888 10.7699 1.89608C11.7701 2.89628 12.332 4.25283 12.332 5.66732V10.334ZM10.9987 10.334V5.66732C10.9987 4.60645 10.5773 3.58904 9.82713 2.83889C9.07698 2.08875 8.05956 1.66732 6.9987 1.66732C5.93783 1.66732 4.92042 2.08875 4.17027 2.83889C3.42013 3.58904 2.9987 4.60645 2.9987 5.66732V10.334H10.9987ZM4.9987 13.0007H8.9987V14.334H4.9987V13.0007Z"/>
  </Svg>
);

export default Icon;
