import { MenuItemsType } from 'uikit'
import { ContextApi } from 'contexts/Localization/types'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('Trade'),
    icon: 'Swap',
    href: '/swap',
    showItemsOnMobile: false,
    items: [
      {
        label: t('Exchange'),
        href: '/swap',
      },
      {
        label: t('Liquidity'),
        href: '/liquidity',
      },
    ],
  },
  {
    label: t('Earn'),
    href: '/farms',
    icon: 'Earn',
    items: [
      {
        label: t('Pools'),
        href: '/pools',
      },
      {
        label: t('Farms'),
        href: '/farms',
      },
    ],
  },
  {
    label: t('Setting'),
    href: '',
    icon: 'Trophy'
  },
 {
    label: t('Win'),
    href: '/win',
    icon: 'Trophy',
    items: [
      {
        label: t('Win'),
        href: '/win',
      },
    ],
  },
  {
    label: t('NTF'),
    href: '/nft',
    icon: 'Nft',
    items: [
      {
        label: t('NFT'),
        href: '/nft',
      },
    ],
  },
]

export default config
