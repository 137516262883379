import styled from 'styled-components'

export const Arrow = styled.div`
  &,
  &::before {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 2px;

    z-index: -1;
  }

  &::before {
    content: '';
    transform: rotate(45deg);
    background: ${({ theme }) => (theme.isDark ? 'rgba(41, 45, 56, 1)' : ' #FFFFFF')};
  }
`

export const StyledTooltip = styled.div`
  padding: 25px;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  max-width: 320px;
  z-index: 101;
  background: ${({ theme }) => (theme.isDark ? 'rgba(41, 45, 56, 1)' : ' #FFFFFF')};
  color: #a2a9bd;
  box-shadow: 0px 24px 44px rgba(0, 0, 0, 0.22);

  &[data-popper-placement^='top'] > ${Arrow} {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] > ${Arrow} {
    top: -4px;
  }

  &[data-popper-placement^='left'] > ${Arrow} {
    right: -4px;
  }

  &[data-popper-placement^='right'] > ${Arrow} {
    left: -4px;
  }
`
