import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg  width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13.5 16.938V19H18.5V21H6.5V19H11.5V16.938C9.5667 16.6942 7.78882 15.7533 6.5 14.2917C5.21119 12.8302 4.50003 10.9486 4.5 9V3H20.5V9C20.5 10.9486 19.7888 12.8302 18.5 14.2917C17.2112 15.7533 15.4333 16.6942 13.5 16.938ZM6.5 5V9C6.5 10.5913 7.13214 12.1174 8.25736 13.2426C9.38258 14.3679 10.9087 15 12.5 15C14.0913 15 15.6174 14.3679 16.7426 13.2426C17.8679 12.1174 18.5 10.5913 18.5 9V5H6.5ZM1.5 5H3.5V9H1.5V5ZM21.5 5H23.5V9H21.5V5Z"/>
  </Svg>
);

export default Icon;
