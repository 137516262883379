import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="15" height="12" viewBox="0 0 8 12" {...props}>
      <path d="M3.52203 6.16591H13.6654V7.83258H3.52203L7.99203 12.3026L6.8137 13.4809L0.332031 6.99924L6.8137 0.517578L7.99203 1.69591L3.52203 6.16591Z" />
    </Svg>
  )
}

export default Icon
