import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M6.62303 0.298833C6.71234 -0.099611 7.28766 -0.0996112 7.37697 0.298833L8.00609 3.10554C8.32825 4.5428 9.45974 5.66848 10.9129 5.99746L13.7002 6.62844C14.0999 6.71893 14.0999 7.28107 13.7002 7.37156L10.9129 8.00254C9.45974 8.33152 8.32825 9.4572 8.00609 10.8945L7.37697 13.7012C7.28766 14.0996 6.71234 14.0996 6.62303 13.7012L5.99391 10.8945C5.67175 9.4572 4.54026 8.33152 3.08706 8.00254L0.299807 7.37156C-0.0999354 7.28107 -0.0999358 6.71893 0.299807 6.62844L3.08706 5.99746C4.54026 5.66848 5.67175 4.5428 5.99391 3.10554L6.62303 0.298833Z" />
    </Svg>
  );
};

export default Icon;
