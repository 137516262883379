import React from 'react'
import useTheme from 'hooks/useTheme'
import styled from 'styled-components'
import { variant as systemVariant, space } from 'styled-system'
import { WarningIcon, ErrorIcon } from '../Svg'
import { Box } from '../Box'
import { MessageProps } from './types'
import variants from './theme'

const Icons = {
  warning: WarningIcon,
  danger: ErrorIcon,
}

const MessageContainer = styled.div`
  display: flex;
  padding: 16px;
  ${space}
  ${systemVariant({
    variants,
  })}
`

const Message: React.FC<MessageProps> = ({ children, variant, icon, ...props }) => {
  const Icon = Icons[variant]
  const { isDark } = useTheme()
  return (
    <MessageContainer
      style={{
        background: isDark ? 'rgba(173, 180, 194, 0.05)' : 'rgba(173, 180, 194, 0.05)',
        border: `1px dashed ${isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(173, 180, 194, 0.15)'}`,
      }}
    >
      <Box mr="12px">
        {icon ?? <Icon color={isDark ? 'rgba(109, 115, 129, 1)' : 'rgba(242, 135, 97, 1)'} width="24px" />}
      </Box>
      {children}
    </MessageContainer>
  )
}

export default Message
