import React, { lazy } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { ResetCSS } from 'uikit'
import BigNumber from 'bignumber.js'
import GlobalStyle from 'style/Global'
import Menu from './components/Menu'
import PageLoader from './components/Loader/PageLoader'
import history from './routerHistory'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
// Views included in the main bundle

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const NotFound = lazy(() => import('./views/NotFound'))
const Account = lazy(() => import('./views/Account'))
const Setting = lazy(() => import('./views/Setting'))
const Store = lazy(() => import('./views/Store'))
const Buy = lazy(() => import('./views/Buy'))
const Contract = lazy(() => import('./views/Contract'))
const Signin = lazy(() => import('./views/Signin'))
const Badge = lazy(() => import('./views/Badge'))
const AccountUser = lazy(() => import('./views/AccountUser'))
const Network = lazy(() => import('./views/Network'))
const MainPage = lazy(() => import('./views/MainPage'))
const Market = lazy(() => import('views/Market'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" component={MainPage} />
            <Route path="/store">
              <Store />
            </Route>
            <Route path="/account" component={Account} />
            <Route path="/buy" component={Buy} />
            <Route path="/signin" component={Signin} />
            <Route path="/badge" component={Badge} />
            <Route path="/account-user" component={AccountUser} />
            <Route path="/setting" component={Setting} />
            <Route path="/contract" component={Contract} />
            <Route path="/network" component={Network} />
            <Route path="/market" component={Market} />
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
    </Router>
  )
}

export default React.memo(App)
