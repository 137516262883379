import { scales, variants } from './types'

export const scaleVariants = {
  [scales.LG]: {
    height: '48px',
      width: '100%',
    padding: '12px',
  },
  [scales.MD]: {
    height: '40px',
    padding: '0 24px',
  },
  [scales.SM]: {
    height: '32px',
    padding: '0 16px',
  },
  [scales.XS]: {
    height: '20px',
    fontSize: '12px',
    padding: '0 8px',
  },
}

export const styleVariants = {
  [variants.PRIMARY]: {
    backgroundColor: '#F28761',
    color: 'checkbox',
    fontSize: '14px',
    borderRadius: '0',
  },
  [variants.SECONDARY]: {
    backgroundColor: 'transparent',
    border: '1.5px solid',
    borderColor: 'rgba(242, 135, 97, 0.1)',
    boxShadow: 'none',
    color: '#F28761',
    fontSize: 14,
    height: 32,
    borderRadius: '0!important',
    ':disabled': {
      backgroundColor: 'transparent',
    },
  },
  [variants.TERTIARY]: {
    backgroundColor: '#F28761',
    color: 'ChartBtn',
    fontSize: '14px',
    borderRadius: '0',
  },
  [variants.SUBTLE]: {
    backgroundColor: 'textSubtle',
    color: 'backgroundAlt',
    borderRadius: "0!important"
  },
  [variants.DANGER]: {
    backgroundColor: '#F28761',
    color: 'white',
  },
  [variants.SUCCESS]: {
    backgroundColor: 'success',
    color: 'white',
  },
  [variants.TEXT]: {
    backgroundColor: 'transparent',
    color: '#F28761',
    fontSize: '14px',
    boxShadow: 'none',
  },
  [variants.LIGHT]: {
    backgroundColor: 'greyBackground',
    color: '#798495',
    boxShadow: 'none',
    borderRadius: "0!important"
  },
}
