import React from 'react'
import Lottie from 'react-lottie'
import styled from 'styled-components'
import { SpinnerProps } from './types'
import Loader from './Loader.json'

const Container = styled.div`
  position: relative;
`

const Spinner: React.FC<SpinnerProps> = ({ size = 128 }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <Container>
      <Lottie options={defaultOptions} height={400} width={400} />
    </Container>
  )
}

export default Spinner
