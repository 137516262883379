import useTheme from 'hooks/useTheme'
import React from 'react'

import Heading from '../../components/Heading/Heading'
import getThemeValue from '../../util/getThemeValue'
import { ModalBody, ModalHeader, ModalTitle, ModalContainer, ModalCloseButton, ModalBackButton } from './styles'
import { ModalProps } from './types'

const Modal: React.FC<ModalProps> = ({
  title,
  onDismiss,
  onBack,
  children,
  hideCloseButton = false,
  bodyPadding = '24px',
  headerBackground = 'transparent',
  minWidth = '320px',
  ...props
}) => {
  const { theme, isDark } = useTheme()

  return (
    <ModalContainer minWidth={minWidth} {...props}>
      <ModalHeader
        style={{ borderBottom: `1px solid ${isDark ? 'rgba(48, 52, 62, 1)' : '#F5F5F5'}` }}
        background={getThemeValue(`colors.${headerBackground}`, headerBackground)(theme)}
      >
        <ModalTitle>
          {onBack && <ModalBackButton onBack={onBack} />}
          <Heading style={{fontSize:"18px"}} color="textSubtle">{title}</Heading>
        </ModalTitle>
        {!hideCloseButton && <ModalCloseButton onDismiss={onDismiss} />}
      </ModalHeader>
      <ModalBody p={bodyPadding}>{children}</ModalBody>
    </ModalContainer>
  )
}

export default Modal
