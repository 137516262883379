import { Colors } from './types'

export const baseColors = {
  failure: '#FF3A3A',
  primary: '#F28761',
  primaryBright: '#53DEE9',
  primaryDark: '#0098A1',
  secondary: '#E4C48C',
  success: '#27FF30',
  warning: '#FFB237',
}

export const additionalColors = {
  binance: '#F0B90B',
  pink: '#FF5C5C',
  gold: '#FFC700',
  silver: '#B2B2B2',
  bronze: '#E7974D',
}

export const lightColors: Colors = {
  ...baseColors,
  ...additionalColors,
  // background: '#FAF9FA',
  background: '#FFFFFF',
  accountSidebarBox: "#FAFAFA",
  accountSidebarBackgroundActiveItem: "#EBEDF2",
  accountSidebarItem: "#181C27",
  greyBackground: '#EBEDF2',
  backgroundDisabled: '#F2876133',
  backgroundAlt: 'rgba(0, 0, 0, 0.22)',
  backgroundAlt2: 'rgba(255, 255, 255, 0.7)',
  contrast: '#191326',
  dropdown: '#F6F6F6',
  dropdownDeep: '#EEEEEE',
  invertedContrast: '#FFFFFF',
  // input: '#0C101C',
  input: '#171A26',
  inputAlt: '#F5F5F5',
  inputBack: '#FFFFFF',
  inputColor: '#161A24',
  // inputSecondary: '#d7caec',
  inputSecondary: 'transparent',
  tertiary: '#EFF4F5',
  text: '#181C27',
  textDisabled: '#ffffff',
  disabled: '#E9EAEB',
  gradients: {
    bubblegum: '#171A26',
    inverseBubblegum: 'linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)',
    cardHeader: 'linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)',
    blue: 'linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)',
    violet: 'linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)',
    violetAlt: 'linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)',
    gold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
    subMenu: '#F8F8F8',
    graph: 'linear-gradient(to right, #0C101C ,#F0E9DD, #0C101C)',
  },

  muted: '#969EAD',

  chipBack: '#222531',
  chipBackAlt: '#F0E9DD',

  select: '#0D121C',
  textSubtle: '#30343E',
  brideCrumbsLine: '#F5F5F5',
  brideCrumbsItems: "#969EAD",
  brideCrumbsActiveItem: "#F28761",
  textGrey: '#969EAD',
  textSubtleSec: '#969EAD',
  navBack: '#FFFFFF',
  cardBack: '#F5F5F5',
  cardBorder: '#F5F5F5',
  cardBackAlt: 'rgba(255, 255, 255, 0.01)',
  cardBackAlt2: 'rgba(173, 180, 194, 0.05)',
  cardIcon: '#798495',
  table: '#F0E9DD',
  gold: '#E4C48C',
  topGraph: 'rgb(246, 133, 27)',
  RadioBorder: "#969EAD",
  bottomGraph: 'rgb(246, 133, 27)',
  border: '#EBEDF2',
  stroke: 'rgba(246, 133, 27, 0.4)',
  hover: 'transparent',
  ChartBtn: "#FFFFFF",
  disabledBar: "rgba(242, 135, 97, 0.2)",
  plusIcon: '#ADB4C2',
  greyBorder: '#F5F5F5',
  percentColor: '#969EAD',
  image: 'url("/images/Union-light.svg"),url("/images/Star.svg"),url("/images/pink-shadow.svg") ',
  checkbox: "#ffffff",
  greyText: "#6D7381",
  scrollBg: "#FAFAFA"

};

export const darkColors: Colors = {
  ...baseColors,
  ...additionalColors,
  secondary: '#9A6AFF',
  background: '#0D121C',
  accountSidebarBox: "#181C27",
  accountSidebarBackgroundActiveItem: "#0D121C",
  accountSidebarItem: "#FFFFFF",
  brideCrumbsLine: '#30343E',
  brideCrumbsItems: "#6D7381",
  brideCrumbsActiveItem: "#FFFFFF",
  greyBackground: '#30343E',
  backgroundDisabled: 'rgba(255, 255, 255, 0.1)',
  backgroundAlt: 'rgba(41, 45, 56, 1)',
  backgroundAlt2: 'rgba(39, 38, 44, 0.7)',
  contrast: '#FFFFFF',
  dropdown: '#1E1D20',
  dropdownDeep: '#100C18',
  invertedContrast: '#191326',
  input: 'transparent',
  inputAlt: '#181C27',
  inputBack: '#161A24',
  inputColor: '#FFFFFF',
  inputSecondary: '#262130',
  primaryDark: '#0098A1',
  tertiary: '#353547',
  text: '#FFFFFF',
  textDisabled: '#ffffff33',
  textSubtle: 'rgba(255, 255, 255, 0.7)',
  textGrey: '#5D6370',
  textSubtleSec: '#6D7381',
  disabled: '#524B63',
  gradients: {
    bubblegum: '#0D121C',
    inverseBubblegum: 'linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)',
    cardHeader: 'linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)',
    blue: 'linear-gradient(180deg, #00707F 0%, #19778C 100%)',
    violet: 'linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)',
    violetAlt: 'linear-gradient(180deg, #434575 0%, #66578D 100%)',
    gold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
    subMenu: '#161A24',
    graph: 'linear-gradient(to right, #E4C48C ,#F0E9DD, #E4C48C)',
  },

  chipBack: '#222531',
  chipBackAlt: '#222531',
  muted: '#6D7381',

  select: '#798495',
  navBack: '#0C101C',
  cardBack: 'transparent',
  cardBorder: '#181C27',
  cardBackAlt: 'rgba(13, 18, 28, 0.01)',
  cardBackAlt2: 'rgba(255, 255, 255, 0.03)',
  cardIcon: '#FFFFFF',
  table: 'rgba(23, 26, 38, 1)',
  gold: '#E4C48C',

  topGraph: 'rgb(255, 255, 255)',
  RadioBorder: "#30343E",
  bottomGraph: 'rgb(255, 255, 255)',
  border: '#181C27',
  stroke: 'rgba(255, 255, 255, 0.4)',
  hover: 'transparent',
  plusIcon: '#30343E',
  greyBorder: '#30343E',
  percentColor: '#ffffff',
  ChartBtn: "#30343E",
  disabledBar: "#30343E",
  image: 'url("/images/Union.svg"),url("/images/Star.svg"),url("/images/pink-shadow.svg")',
  checkbox: "#181C27",
  greyText: "#ffffff",
  scrollBg: "#090D16"
};
