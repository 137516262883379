import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width='10' height='6' fill = 'none' viewBox="0 0 10 6" {...props}>
      <path d="M1.66602 1.33301L4.99935 4.66634L8.33268 1.33301" stroke="#6D7381" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
};

export default Icon;
