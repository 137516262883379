import useTheme from 'hooks/useTheme'
import React from 'react'
import styled from 'styled-components'
import Button from '../Button/Button'
import { BaseButtonProps, PolymorphicComponent, variants } from '../Button/types'
import { ButtonMenuItemProps } from './types'

interface InactiveButtonProps extends BaseButtonProps {
  forwardedAs: BaseButtonProps['as']
}

const InactiveButton: PolymorphicComponent<InactiveButtonProps, 'button'> = styled(Button)<InactiveButtonProps>`
  background-color: transparent;
  color: #6d7381;
  &:hover:not(:disabled):not(:active) {
    background-color: transparent;
  }
`

const ButtonMenuItem: PolymorphicComponent<ButtonMenuItemProps, 'button'> = ({
   isActive = false,
   alt = false,
   variant = variants.TERTIARY,
   sub = false,
   as,
  to,
   ...props
 }: ButtonMenuItemProps) => {
  const { theme, isDark } = useTheme()
  if (!isActive) {

    return (
      <InactiveButton
        color="#969EAD"
        height={alt ? (sub ? '32px' : '40px') : '72px'}
        forwardedAs={as}
        variant={variant}
        {...props}
      />
    )
  }
  return (
    <Button
      style={{
        height: alt ? '32px' : '72px',
        color: isDark ? '#FFFFFF' : '#6D7381',
        fontWeight: 500,
        minHeight: sub ? '40px' : 'inherit',
        borderRadius: 0,
        backgroundImage: alt
          ? 'none'
          : `linear-gradient(to bottom, ${isDark ? 'rgba(242, 135, 97, 0)' : 'rgba(255, 255, 255, 0)'}, ${
            isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(242, 135, 97, 0.1)'
          })`,
        backgroundColor: alt ? (isDark ? '#30343E' : '#FFFFFF') : 'transparent',
        borderBottom: alt ? '0px' : `3px solid ${isDark ? '#FFFFFF' : '#F28761'}`,
      }}
      variant={variants.TERTIARY}
      as={as}
      to={to}
      {...props}
    />
  )
}

export default ButtonMenuItem
